import { createContext, useState } from "react";

export type Departments = {
  name?: string;
  id?: number;
};

export type DepartmentContext = {
  department: string;
  setDepartment: (nextDepartment: string) => void;
};

type Props = {
  children: React.ReactNode;
};

export const DepartmentContext = createContext({} as DepartmentContext);

export const DepartmentsProvider: React.FC<Props> = ({ children }: Props) => {
  const [department, setDepartment] = useState<string>("1"); //TO-DO : Here 1 will be removed after the API is ready.
  return (
    <DepartmentContext.Provider value={{ department, setDepartment }}>
      {children}
    </DepartmentContext.Provider>
  );
};
