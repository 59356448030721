import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { lazy } from "react";
import { SpinningLoader } from "@packages/theme-mui-v5";
import ApplicationAccessManagement from "@packages/application-access-management";
import applicationConfig, { basePath } from "./config";
import Footer from "./components/Footer";

const LandingPage = SpinningLoader(lazy(() => import("./pages/LandingPage")));
const MaintenanceHistory = SpinningLoader(lazy(() => import("./pages/MaintenanceHistory")));

const Router = () => {
  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="maintenance-history" element={<MaintenanceHistory />} />
        <Route
          path="admin/*"
          element={
            <ApplicationAccessManagement
              basePath={basePath}
              applicationId={applicationConfig.applicationId}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
