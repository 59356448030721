import { FC, useContext } from "react";
import { Box, Fab, FormControl, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { DepartmentContext } from "./DepartmentsProvider";
import { Department } from "@packages/service-api";

const BoxMain = styled(Box)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: theme.palette.common.white
  },
  "& .MuiInputBase-inputSizeSmall": {
    color: theme.palette.common.white
  },
  "& .MuiOutlinedInput-input": {
    textTransform: "none",
    fontWeight: "bold"
  }
}));

const Footer: FC = () => {
  const { department, setDepartment } = useContext(DepartmentContext);

  const handleChange = (event: SelectChangeEvent) => {
    setDepartment(event.target.value as string);
  };

  const depts: Department[] = [
    {
      name: "Electrical",
      id: 1
    },
    {
      name: "Mechanical",
      id: 2
    },
    {
      name: "Maintenance",
      id: 3
    }
  ];

  return (
    <BoxMain>
      <Fab
        color="primary"
        aria-label="info"
        size="large"
        variant="extended"
        title="Change department here"
        sx={{
          position: "fixed",
          bottom: 10,
          right: 15
        }}
      >
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="departmentLabel"
              id="department-select"
              value={department}
              size="small"
              style={{ border: "none" }}
              onChange={handleChange}
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                  border: 0
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0
                }
              }}
            >
              {depts?.map((d) => (
                <MenuItem key={d.id} value={d.id}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Fab>
    </BoxMain>
  );
};

export default Footer;
